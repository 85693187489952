// Generated by ReScript, PLEASE EDIT WITH CARE

import * as H1 from "../../../styleguide/components/Heading/H1.res.js";
import * as Container from "../../../styleguide/components/Container/Container.res.js";
import * as BlogPostNavbar from "../common/navbar/BlogPostNavbar.res.js";
import * as BlogPostNewForm from "./BlogPostNewForm.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as BlogPostNewScss from "./BlogPostNew.scss";

var css = BlogPostNewScss;

function BlogPostNew$default(props) {
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx(BlogPostNavbar.make, {
                      selectedCategory: "New"
                    }),
                JsxRuntime.jsxs(Container.make, {
                      className: css.container,
                      children: [
                        JsxRuntime.jsx(H1.make, {
                              className: css.pageTitle,
                              children: "New Blog Post"
                            }),
                        JsxRuntime.jsx(BlogPostNewForm.make, {
                              categories: props.categories
                            })
                      ]
                    })
              ],
              className: css.pageContainer
            });
}

var $$default = BlogPostNew$default;

export {
  css ,
  $$default as default,
}
/* css Not a pure module */
