// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ID from "../../../libs/ID.res.js";
import * as Col from "../../../styleguide/components/Grid/Col/Col.res.js";
import * as Row from "../../../styleguide/components/Grid/Row/Row.res.js";
import * as Url from "../../../utils/Url.res.js";
import * as Grid from "../../../styleguide/components/Grid/Grid.res.js";
import * as Label from "../../../styleguide/forms/Label/Label.res.js";
import * as Quill from "../../../bindings/Quill.res.js";
import * as React from "react";
import * as Button from "../../../styleguide/components/Button/Button.res.js";
import * as Editor from "../../../styleguide/forms/Editor/Editor.res.js";
import * as Select from "../../../styleguide/forms/Select/Select.res.js";
import * as $$Promise from "../../../bindings/Promise.res.js";
import * as Textarea from "../../../styleguide/forms/Textarea/Textarea.res.js";
import * as Uploader from "../../../styleguide/forms/Uploader/Uploader.res.js";
import * as Formality from "re-formality/src/Formality.res.js";
import * as TextField from "../../../styleguide/forms/TextField/TextField.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as DatePicker from "../../../styleguide/forms/DatePicker/DatePicker.res.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Api_BlogPost from "../../../api/blog-posts/Api_BlogPost.res.js";
import * as ErrorMessage from "../../../styleguide/forms/ErrorMessage/ErrorMessage.res.js";
import * as SentryLogger from "../../../loggers/SentryLogger.res.js";
import * as Routes_BlogPost from "../../../routes/common/Routes_BlogPost.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as IconAdditionalUpload from "../../../styleguide/icons/IconAdditionalUpload.res.js";
import * as BlogPostFeaturedImage from "./BlogPostFeaturedImage.res.js";
import * as BlogPostNewFormScss from "./BlogPostNewForm.scss";
import * as Formality__ReactUpdate from "re-formality/src/Formality__ReactUpdate.res.js";

var css = BlogPostNewFormScss;

var validators_publishedAt = {
  strategy: "OnFirstSuccessOrFirstBlur",
  validate: (function (input) {
      var match = input.publishedAt;
      if (match !== undefined) {
        return {
                TAG: "Ok",
                _0: input.publishedAt
              };
      } else {
        return {
                TAG: "Error",
                _0: "Published date is required"
              };
      }
    })
};

var validators_blogPostCategoryId = {
  strategy: "OnFirstSuccessOrFirstBlur",
  validate: (function (input) {
      var match = input.blogPostCategoryId;
      if (match === "") {
        return {
                TAG: "Error",
                _0: "Blog post category is required"
              };
      } else {
        return {
                TAG: "Ok",
                _0: input.blogPostCategoryId
              };
      }
    })
};

var validators_approved = {
  strategy: "OnFirstSuccessOrFirstBlur",
  validate: (function (input) {
      var match = input.approved;
      if (match === "") {
        return {
                TAG: "Error",
                _0: "Published is required"
              };
      } else {
        return {
                TAG: "Ok",
                _0: input.approved
              };
      }
    })
};

var validators_metaDescription = {
  strategy: "OnFirstSuccessOrFirstBlur",
  validate: (function (input) {
      var length = input.metaDescription.length;
      if (length !== 0) {
        if (length < 50) {
          return {
                  TAG: "Error",
                  _0: "Meta description must be more than 50 characters."
                };
        } else if (length > 500) {
          return {
                  TAG: "Error",
                  _0: "Meta description must no more than 500 characters."
                };
        } else {
          return {
                  TAG: "Ok",
                  _0: input.metaDescription
                };
        }
      } else {
        return {
                TAG: "Error",
                _0: "Meta description is required"
              };
      }
    })
};

var validators_seoTitle = {
  strategy: "OnFirstSuccessOrFirstBlur",
  validate: (function (input) {
      var length = input.seoTitle.length;
      if (length !== 0) {
        if (length < 40) {
          return {
                  TAG: "Error",
                  _0: "Meta title must be more than 40 characters."
                };
        } else if (length > 200) {
          return {
                  TAG: "Error",
                  _0: "Meta title must no more than 200 characters."
                };
        } else {
          return {
                  TAG: "Ok",
                  _0: input.seoTitle
                };
        }
      } else {
        return {
                TAG: "Error",
                _0: "Meta title is required"
              };
      }
    })
};

var validators_title = {
  strategy: "OnFirstSuccessOrFirstBlur",
  validate: (function (input) {
      var length = input.title.length;
      if (length !== 0) {
        if (length < 20) {
          return {
                  TAG: "Error",
                  _0: "Title must be more than 20 characters."
                };
        } else if (length > 100) {
          return {
                  TAG: "Error",
                  _0: "Title must be no more than 100 characters."
                };
        } else {
          return {
                  TAG: "Ok",
                  _0: input.title
                };
        }
      } else {
        return {
                TAG: "Error",
                _0: "Title is required."
              };
      }
    })
};

var validators = {
  publishedAt: validators_publishedAt,
  blogPostCategoryId: validators_blogPostCategoryId,
  approved: validators_approved,
  metaDescription: validators_metaDescription,
  seoTitle: validators_seoTitle,
  title: validators_title
};

function initialFieldsStatuses(_input) {
  return {
          publishedAt: "Pristine",
          blogPostCategoryId: "Pristine",
          approved: "Pristine",
          metaDescription: "Pristine",
          seoTitle: "Pristine",
          title: "Pristine"
        };
}

function initialState(input) {
  return {
          input: input,
          fieldsStatuses: initialFieldsStatuses(input),
          collectionsStatuses: undefined,
          formStatus: "Editing",
          submissionStatus: "NeverSubmitted"
        };
}

function validateForm(input, validators, fieldsStatuses) {
  var match = fieldsStatuses.publishedAt;
  var tmp;
  tmp = typeof match !== "object" ? validators.publishedAt.validate(input) : match._0;
  var match$1 = fieldsStatuses.blogPostCategoryId;
  var tmp$1;
  tmp$1 = typeof match$1 !== "object" ? validators.blogPostCategoryId.validate(input) : match$1._0;
  var match$2 = fieldsStatuses.approved;
  var tmp$2;
  tmp$2 = typeof match$2 !== "object" ? validators.approved.validate(input) : match$2._0;
  var match$3 = fieldsStatuses.metaDescription;
  var tmp$3;
  tmp$3 = typeof match$3 !== "object" ? validators.metaDescription.validate(input) : match$3._0;
  var match$4 = fieldsStatuses.seoTitle;
  var tmp$4;
  tmp$4 = typeof match$4 !== "object" ? validators.seoTitle.validate(input) : match$4._0;
  var match$5 = fieldsStatuses.title;
  var tmp$5;
  tmp$5 = typeof match$5 !== "object" ? validators.title.validate(input) : match$5._0;
  var publishedAtResult = tmp;
  var publishedAtResult$1;
  if (publishedAtResult.TAG === "Ok") {
    var blogPostCategoryIdResult = tmp$1;
    if (blogPostCategoryIdResult.TAG === "Ok") {
      var approvedResult = tmp$2;
      if (approvedResult.TAG === "Ok") {
        var metaDescriptionResult = tmp$3;
        if (metaDescriptionResult.TAG === "Ok") {
          var seoTitleResult = tmp$4;
          if (seoTitleResult.TAG === "Ok") {
            var titleResult = tmp$5;
            if (titleResult.TAG === "Ok") {
              return {
                      TAG: "Valid",
                      output: {
                        title: titleResult._0,
                        seoTitle: seoTitleResult._0,
                        metaDescription: metaDescriptionResult._0,
                        approved: approvedResult._0,
                        blogPostCategoryId: blogPostCategoryIdResult._0,
                        publishedAt: publishedAtResult._0
                      },
                      fieldsStatuses: {
                        publishedAt: {
                          TAG: "Dirty",
                          _0: publishedAtResult,
                          _1: "Shown"
                        },
                        blogPostCategoryId: {
                          TAG: "Dirty",
                          _0: blogPostCategoryIdResult,
                          _1: "Shown"
                        },
                        approved: {
                          TAG: "Dirty",
                          _0: approvedResult,
                          _1: "Shown"
                        },
                        metaDescription: {
                          TAG: "Dirty",
                          _0: metaDescriptionResult,
                          _1: "Shown"
                        },
                        seoTitle: {
                          TAG: "Dirty",
                          _0: seoTitleResult,
                          _1: "Shown"
                        },
                        title: {
                          TAG: "Dirty",
                          _0: titleResult,
                          _1: "Shown"
                        }
                      },
                      collectionsStatuses: undefined
                    };
            }
            publishedAtResult$1 = publishedAtResult;
          } else {
            publishedAtResult$1 = publishedAtResult;
          }
        } else {
          publishedAtResult$1 = publishedAtResult;
        }
      } else {
        publishedAtResult$1 = publishedAtResult;
      }
    } else {
      publishedAtResult$1 = publishedAtResult;
    }
  } else {
    publishedAtResult$1 = publishedAtResult;
  }
  return {
          TAG: "Invalid",
          fieldsStatuses: {
            publishedAt: {
              TAG: "Dirty",
              _0: publishedAtResult$1,
              _1: "Shown"
            },
            blogPostCategoryId: {
              TAG: "Dirty",
              _0: tmp$1,
              _1: "Shown"
            },
            approved: {
              TAG: "Dirty",
              _0: tmp$2,
              _1: "Shown"
            },
            metaDescription: {
              TAG: "Dirty",
              _0: tmp$3,
              _1: "Shown"
            },
            seoTitle: {
              TAG: "Dirty",
              _0: tmp$4,
              _1: "Shown"
            },
            title: {
              TAG: "Dirty",
              _0: tmp$5,
              _1: "Shown"
            }
          },
          collectionsStatuses: undefined
        };
}

function useForm(initialInput, onSubmit) {
  var memoizedInitialState = React.useMemo((function () {
          return initialState(initialInput);
        }), [initialInput]);
  var match = Formality__ReactUpdate.useReducer(memoizedInitialState, (function (state, action) {
          if (typeof action !== "object") {
            switch (action) {
              case "BlurPublishedAtField" :
                  var result = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.publishedAt, validators_publishedAt, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  publishedAt: status,
                                  blogPostCategoryId: init.blogPostCategoryId,
                                  approved: init.approved,
                                  metaDescription: init.metaDescription,
                                  seoTitle: init.seoTitle,
                                  title: init.title
                                };
                        }));
                  if (result !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurBlogPostCategoryIdField" :
                  var result$1 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.blogPostCategoryId, validators_blogPostCategoryId, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  publishedAt: init.publishedAt,
                                  blogPostCategoryId: status,
                                  approved: init.approved,
                                  metaDescription: init.metaDescription,
                                  seoTitle: init.seoTitle,
                                  title: init.title
                                };
                        }));
                  if (result$1 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$1,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurApprovedField" :
                  var result$2 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.approved, validators_approved, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  publishedAt: init.publishedAt,
                                  blogPostCategoryId: init.blogPostCategoryId,
                                  approved: status,
                                  metaDescription: init.metaDescription,
                                  seoTitle: init.seoTitle,
                                  title: init.title
                                };
                        }));
                  if (result$2 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$2,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurMetaDescriptionField" :
                  var result$3 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.metaDescription, validators_metaDescription, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  publishedAt: init.publishedAt,
                                  blogPostCategoryId: init.blogPostCategoryId,
                                  approved: init.approved,
                                  metaDescription: status,
                                  seoTitle: init.seoTitle,
                                  title: init.title
                                };
                        }));
                  if (result$3 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$3,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurSeoTitleField" :
                  var result$4 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.seoTitle, validators_seoTitle, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  publishedAt: init.publishedAt,
                                  blogPostCategoryId: init.blogPostCategoryId,
                                  approved: init.approved,
                                  metaDescription: init.metaDescription,
                                  seoTitle: status,
                                  title: init.title
                                };
                        }));
                  if (result$4 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$4,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurTitleField" :
                  var result$5 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.title, validators_title, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  publishedAt: init.publishedAt,
                                  blogPostCategoryId: init.blogPostCategoryId,
                                  approved: init.approved,
                                  metaDescription: init.metaDescription,
                                  seoTitle: init.seoTitle,
                                  title: status
                                };
                        }));
                  if (result$5 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$5,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "Submit" :
                  var match = state.formStatus;
                  if (typeof match === "object" && match.TAG === "Submitting") {
                    return "NoUpdate";
                  }
                  var match$1 = validateForm(state.input, validators, state.fieldsStatuses);
                  if (match$1.TAG !== "Valid") {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: match$1.fieldsStatuses,
                              collectionsStatuses: match$1.collectionsStatuses,
                              formStatus: "Editing",
                              submissionStatus: "AttemptedToSubmit"
                            }
                          };
                  }
                  var output = match$1.output;
                  var error = state.formStatus;
                  var tmp;
                  tmp = typeof error !== "object" || error.TAG !== "SubmissionFailed" ? undefined : Caml_option.some(error._0);
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            input: state.input,
                            fieldsStatuses: match$1.fieldsStatuses,
                            collectionsStatuses: match$1.collectionsStatuses,
                            formStatus: {
                              TAG: "Submitting",
                              _0: tmp
                            },
                            submissionStatus: "AttemptedToSubmit"
                          },
                          _1: (function (param) {
                              var dispatch = param.dispatch;
                              onSubmit(output, {
                                    notifyOnSuccess: (function (input) {
                                        dispatch({
                                              TAG: "SetSubmittedStatus",
                                              _0: input
                                            });
                                      }),
                                    notifyOnFailure: (function (error) {
                                        dispatch({
                                              TAG: "SetSubmissionFailedStatus",
                                              _0: error
                                            });
                                      }),
                                    reset: (function () {
                                        dispatch("Reset");
                                      }),
                                    dismissSubmissionResult: (function () {
                                        dispatch("DismissSubmissionResult");
                                      })
                                  });
                            })
                        };
                  break;
              case "DismissSubmissionError" :
                  var match$2 = state.formStatus;
                  if (typeof match$2 !== "object" || match$2.TAG !== "SubmissionFailed") {
                    return "NoUpdate";
                  } else {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: "Editing",
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case "DismissSubmissionResult" :
                  var match$3 = state.formStatus;
                  if (typeof match$3 !== "object") {
                    if (match$3 === "Editing") {
                      return "NoUpdate";
                    }
                    
                  } else if (match$3.TAG === "Submitting") {
                    return "NoUpdate";
                  }
                  return {
                          TAG: "Update",
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: "Editing",
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "Reset" :
                  return {
                          TAG: "Update",
                          _0: initialState(initialInput)
                        };
              
            }
          } else {
            switch (action.TAG) {
              case "UpdatePublishedAtField" :
                  var nextInput = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput, state.fieldsStatuses.publishedAt, state.submissionStatus, validators_publishedAt, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            publishedAt: status,
                                            blogPostCategoryId: init.blogPostCategoryId,
                                            approved: init.approved,
                                            metaDescription: init.metaDescription,
                                            seoTitle: init.seoTitle,
                                            title: init.title
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateBlogPostCategoryIdField" :
                  var nextInput$1 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$1,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$1, state.fieldsStatuses.blogPostCategoryId, state.submissionStatus, validators_blogPostCategoryId, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            publishedAt: init.publishedAt,
                                            blogPostCategoryId: status,
                                            approved: init.approved,
                                            metaDescription: init.metaDescription,
                                            seoTitle: init.seoTitle,
                                            title: init.title
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateApprovedField" :
                  var nextInput$2 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$2,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$2, state.fieldsStatuses.approved, state.submissionStatus, validators_approved, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            publishedAt: init.publishedAt,
                                            blogPostCategoryId: init.blogPostCategoryId,
                                            approved: status,
                                            metaDescription: init.metaDescription,
                                            seoTitle: init.seoTitle,
                                            title: init.title
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateMetaDescriptionField" :
                  var nextInput$3 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$3,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$3, state.fieldsStatuses.metaDescription, state.submissionStatus, validators_metaDescription, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            publishedAt: init.publishedAt,
                                            blogPostCategoryId: init.blogPostCategoryId,
                                            approved: init.approved,
                                            metaDescription: status,
                                            seoTitle: init.seoTitle,
                                            title: init.title
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateSeoTitleField" :
                  var nextInput$4 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$4,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$4, state.fieldsStatuses.seoTitle, state.submissionStatus, validators_seoTitle, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            publishedAt: init.publishedAt,
                                            blogPostCategoryId: init.blogPostCategoryId,
                                            approved: init.approved,
                                            metaDescription: init.metaDescription,
                                            seoTitle: status,
                                            title: init.title
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateTitleField" :
                  var nextInput$5 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$5,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$5, state.fieldsStatuses.title, state.submissionStatus, validators_title, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            publishedAt: init.publishedAt,
                                            blogPostCategoryId: init.blogPostCategoryId,
                                            approved: init.approved,
                                            metaDescription: init.metaDescription,
                                            seoTitle: init.seoTitle,
                                            title: status
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "SetSubmittedStatus" :
                  var input = action._0;
                  if (input !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: input,
                              fieldsStatuses: initialFieldsStatuses(input),
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: "Submitted",
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: initialFieldsStatuses(state.input),
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: "Submitted",
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case "SetSubmissionFailedStatus" :
                  return {
                          TAG: "Update",
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: {
                              TAG: "SubmissionFailed",
                              _0: action._0
                            },
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "MapSubmissionError" :
                  var map = action._0;
                  var error$1 = state.formStatus;
                  if (typeof error$1 !== "object") {
                    return "NoUpdate";
                  }
                  if (error$1.TAG !== "Submitting") {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: "SubmissionFailed",
                                _0: map(error$1._0)
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
                  var error$2 = error$1._0;
                  if (error$2 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: "Submitting",
                                _0: Caml_option.some(map(Caml_option.valFromOption(error$2)))
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              
            }
          }
        }));
  var dispatch = match[1];
  var state = match[0];
  var match$1 = state.formStatus;
  var tmp;
  tmp = typeof match$1 !== "object" || match$1.TAG !== "Submitting" ? false : true;
  return {
          updatePublishedAt: (function (nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdatePublishedAtField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      })
                  });
            }),
          updateBlogPostCategoryId: (function (nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateBlogPostCategoryIdField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      })
                  });
            }),
          updateApproved: (function (nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateApprovedField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      })
                  });
            }),
          updateMetaDescription: (function (nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateMetaDescriptionField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      })
                  });
            }),
          updateSeoTitle: (function (nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateSeoTitleField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      })
                  });
            }),
          updateTitle: (function (nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateTitleField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      })
                  });
            }),
          blurPublishedAt: (function () {
              dispatch("BlurPublishedAtField");
            }),
          blurBlogPostCategoryId: (function () {
              dispatch("BlurBlogPostCategoryIdField");
            }),
          blurApproved: (function () {
              dispatch("BlurApprovedField");
            }),
          blurMetaDescription: (function () {
              dispatch("BlurMetaDescriptionField");
            }),
          blurSeoTitle: (function () {
              dispatch("BlurSeoTitleField");
            }),
          blurTitle: (function () {
              dispatch("BlurTitleField");
            }),
          publishedAtResult: Formality.exposeFieldResult(state.fieldsStatuses.publishedAt),
          blogPostCategoryIdResult: Formality.exposeFieldResult(state.fieldsStatuses.blogPostCategoryId),
          approvedResult: Formality.exposeFieldResult(state.fieldsStatuses.approved),
          metaDescriptionResult: Formality.exposeFieldResult(state.fieldsStatuses.metaDescription),
          seoTitleResult: Formality.exposeFieldResult(state.fieldsStatuses.seoTitle),
          titleResult: Formality.exposeFieldResult(state.fieldsStatuses.title),
          input: state.input,
          status: state.formStatus,
          dirty: (function () {
              var match = state.fieldsStatuses;
              var tmp = match.publishedAt;
              if (typeof tmp === "object") {
                return true;
              }
              var tmp$1 = match.blogPostCategoryId;
              if (typeof tmp$1 === "object") {
                return true;
              }
              var tmp$2 = match.approved;
              if (typeof tmp$2 === "object") {
                return true;
              }
              var tmp$3 = match.metaDescription;
              if (typeof tmp$3 === "object") {
                return true;
              }
              var tmp$4 = match.seoTitle;
              if (typeof tmp$4 === "object") {
                return true;
              }
              var tmp$5 = match.title;
              if (typeof tmp$5 !== "object") {
                return false;
              } else {
                return true;
              }
            }),
          valid: (function () {
              var match = validateForm(state.input, validators, state.fieldsStatuses);
              if (match.TAG === "Valid") {
                return true;
              } else {
                return false;
              }
            }),
          submitting: tmp,
          submit: (function () {
              dispatch("Submit");
            }),
          dismissSubmissionError: (function () {
              dispatch("DismissSubmissionError");
            }),
          dismissSubmissionResult: (function () {
              dispatch("DismissSubmissionResult");
            }),
          mapSubmissionError: (function (map) {
              dispatch({
                    TAG: "MapSubmissionError",
                    _0: map
                  });
            }),
          reset: (function () {
              dispatch("Reset");
            })
        };
}

var BlogPostNewForm = {
  validators: validators,
  initialFieldsStatuses: initialFieldsStatuses,
  initialCollectionsStatuses: undefined,
  initialState: initialState,
  validateForm: validateForm,
  useForm: useForm
};

function reducer(state, action) {
  if (action.TAG === "SetFeaturedImage") {
    return {
            featuredImage: action._0,
            editorState: state.editorState
          };
  } else {
    return {
            featuredImage: state.featuredImage,
            editorState: Editor.State.compose(state.editorState, action._0)
          };
  }
}

function BlogPostNewForm$1(props) {
  var categories = props.categories;
  var initialState_editorState = Editor.State.make();
  var initialState = {
    featuredImage: undefined,
    editorState: initialState_editorState
  };
  var match = React.useReducer(reducer, initialState);
  var dispatch = match[1];
  var state = match[0];
  var initialInput = React.useMemo((function () {
          var category = Belt_Array.get(categories, 0);
          return {
                  title: "",
                  seoTitle: "",
                  metaDescription: "",
                  approved: "False",
                  blogPostCategoryId: category !== undefined ? ID.toString(category.id) : "1",
                  publishedAt: undefined
                };
        }), []);
  var form = useForm(initialInput, (function (output, cb) {
          $$Promise.wait(Api_BlogPost.create(output.title, document.querySelector(".ql-editor").innerHTML, Belt_Option.getExn(state.featuredImage), output.seoTitle, output.metaDescription, output.approved, output.blogPostCategoryId, output.publishedAt), (function (x) {
                  if (x.TAG === "Ok") {
                    return Url.visit(Routes_BlogPost.show(x._0.slug));
                  } else {
                    return SentryLogger.error1({
                                rootModule: "BlogPostNewForm",
                                subModulePath: /* [] */0,
                                value: "make",
                                fullPath: "BlogPostNewForm.make"
                              }, "CreateNewBlogPost::Error", [
                                "Error",
                                cb.notifyOnFailure()
                              ]);
                  }
                }));
        }));
  var fieldError = function (field) {
    if (field !== undefined && field.TAG !== "Ok") {
      return JsxRuntime.jsx(ErrorMessage.make, {
                  className: css.error,
                  children: field._0
                });
    } else {
      return null;
    }
  };
  var containerRef = React.useRef(null);
  var editorRef = React.useRef(undefined);
  React.useEffect((function () {
          var editor = Belt_Option.getExn(Belt_Option.map(Caml_option.nullable_to_opt(containerRef.current), (function (container) {
                      return Quill.make("Type here...", undefined, undefined, Quill.blogPostToolbarOptions, {}, undefined, container);
                    })));
          var listener = function (delta, param) {
            dispatch({
                  TAG: "SetEditorState",
                  _0: delta
                });
          };
          editorRef.current = Caml_option.some(editor);
          editor.on("text-change", listener);
          return (function () {
                    editor.off("text-change", listener);
                  });
        }), []);
  var match$1 = state.featuredImage;
  var match$2 = form.status;
  var tmp;
  tmp = typeof match$2 !== "object" || match$2.TAG !== "SubmissionFailed" ? null : JsxRuntime.jsxs(Row.make, {
          className: "row",
          children: [
            JsxRuntime.jsx(Col.make, {
                  className: css.buttonHiddenContainer,
                  md: 3,
                  lg: 3,
                  children: JsxRuntime.jsx("div", {})
                }),
            JsxRuntime.jsx(Col.make, {
                  className: css.buttonHiddenContainer,
                  md: 9,
                  lg: 9,
                  children: JsxRuntime.jsx(ErrorMessage.make, {
                        className: css.error,
                        children: "Something went wrong."
                      })
                })
          ]
        });
  var match$3 = state.featuredImage;
  return JsxRuntime.jsx("form", {
              children: JsxRuntime.jsxs(Grid.make, {
                    className: css.grid,
                    children: [
                      JsxRuntime.jsxs(Row.make, {
                            className: css.row,
                            children: [
                              JsxRuntime.jsx(Col.make, {
                                    className: css.labelContainer,
                                    md: 3,
                                    lg: 3,
                                    children: JsxRuntime.jsx(Label.make, {
                                          forId: "blog-post-form--title",
                                          children: "Title"
                                        })
                                  }),
                              JsxRuntime.jsxs(Col.make, {
                                    className: css.fieldContainer,
                                    md: 9,
                                    lg: 9,
                                    children: [
                                      JsxRuntime.jsx(TextField.make, {
                                            id: "blog-post-form--title",
                                            value: form.input.title,
                                            placeholder: "Add a title for your new blog post.",
                                            onChange: (function ($$event) {
                                                form.updateTitle((function (input, value) {
                                                        return {
                                                                title: value,
                                                                seoTitle: input.seoTitle,
                                                                metaDescription: input.metaDescription,
                                                                approved: input.approved,
                                                                blogPostCategoryId: input.blogPostCategoryId,
                                                                publishedAt: input.publishedAt
                                                              };
                                                      }), $$event.target.value);
                                              })
                                          }),
                                      fieldError(form.titleResult)
                                    ]
                                  })
                            ]
                          }),
                      JsxRuntime.jsxs(Row.make, {
                            className: css.row,
                            children: [
                              JsxRuntime.jsx(Col.make, {
                                    className: css.labelContainer,
                                    md: 3,
                                    lg: 3,
                                    children: JsxRuntime.jsx(Label.make, {
                                          forId: "blog-post-form--blog-post-body",
                                          children: "Body"
                                        })
                                  }),
                              JsxRuntime.jsx(Col.make, {
                                    className: css.fieldContainer,
                                    md: 9,
                                    lg: 9,
                                    children: JsxRuntime.jsx("div", {
                                          children: JsxRuntime.jsx(Editor.make, {
                                                ref: containerRef
                                              }),
                                          className: css.editor
                                        })
                                  })
                            ]
                          }),
                      JsxRuntime.jsxs(Row.make, {
                            className: css.row,
                            children: [
                              JsxRuntime.jsx(Col.make, {
                                    className: css.labelContainer,
                                    md: 3,
                                    lg: 3,
                                    children: JsxRuntime.jsx(Label.make, {
                                          forId: "blog-post-form--seo-title",
                                          children: "Meta Title"
                                        })
                                  }),
                              JsxRuntime.jsxs(Col.make, {
                                    className: css.fieldContainer,
                                    md: 9,
                                    lg: 9,
                                    children: [
                                      JsxRuntime.jsx(TextField.make, {
                                            id: "blog-post-form--seo-title",
                                            value: form.input.seoTitle,
                                            placeholder: "Add a meta title.",
                                            onChange: (function ($$event) {
                                                form.updateSeoTitle((function (input, value) {
                                                        return {
                                                                title: input.title,
                                                                seoTitle: value,
                                                                metaDescription: input.metaDescription,
                                                                approved: input.approved,
                                                                blogPostCategoryId: input.blogPostCategoryId,
                                                                publishedAt: input.publishedAt
                                                              };
                                                      }), $$event.target.value);
                                              })
                                          }),
                                      fieldError(form.seoTitleResult)
                                    ]
                                  })
                            ]
                          }),
                      JsxRuntime.jsxs(Row.make, {
                            className: css.row,
                            children: [
                              JsxRuntime.jsx(Col.make, {
                                    className: css.labelContainer,
                                    md: 3,
                                    lg: 3,
                                    children: JsxRuntime.jsx(Label.make, {
                                          forId: "blog-post-form--meta-description",
                                          children: "Meta Description"
                                        })
                                  }),
                              JsxRuntime.jsxs(Col.make, {
                                    className: css.fieldContainer,
                                    md: 9,
                                    lg: 9,
                                    children: [
                                      JsxRuntime.jsx(Textarea.make, {
                                            id: "blog-post-form--meta-description",
                                            value: form.input.metaDescription,
                                            placeholder: "Add a meta description.",
                                            onChange: (function ($$event) {
                                                form.updateMetaDescription((function (input, value) {
                                                        return {
                                                                title: input.title,
                                                                seoTitle: input.seoTitle,
                                                                metaDescription: value,
                                                                approved: input.approved,
                                                                blogPostCategoryId: input.blogPostCategoryId,
                                                                publishedAt: input.publishedAt
                                                              };
                                                      }), $$event.target.value);
                                              })
                                          }),
                                      fieldError(form.metaDescriptionResult)
                                    ]
                                  })
                            ]
                          }),
                      JsxRuntime.jsxs(Row.make, {
                            className: css.row,
                            children: [
                              JsxRuntime.jsx(Col.make, {
                                    className: css.labelContainer,
                                    md: 3,
                                    lg: 3,
                                    children: JsxRuntime.jsx(Label.make, {
                                          forId: "blog-post-form--featured-image",
                                          children: "Featured Image"
                                        })
                                  }),
                              JsxRuntime.jsxs(Col.make, {
                                    className: css.fieldContainer,
                                    md: 9,
                                    lg: 9,
                                    children: [
                                      JsxRuntime.jsx(BlogPostFeaturedImage.make, {
                                            featuredImage: state.featuredImage,
                                            removeImage: (function (param) {
                                                dispatch({
                                                      TAG: "SetFeaturedImage",
                                                      _0: undefined
                                                    });
                                              })
                                          }),
                                      JsxRuntime.jsx(Uploader.make, {
                                            allowed: [
                                              "Jpg",
                                              "Png"
                                            ],
                                            multiple: false,
                                            upload: (function (files) {
                                                Belt_Array.map(files, (function (file) {
                                                        var match = Uploader.FileType.fromMime(file.type);
                                                        switch (match) {
                                                          case "Jpg" :
                                                          case "Png" :
                                                              return dispatch({
                                                                          TAG: "SetFeaturedImage",
                                                                          _0: Caml_option.some(file)
                                                                        });
                                                          default:
                                                            return ;
                                                        }
                                                      }));
                                              }),
                                            setUploadHandler: (function (prim) {
                                                
                                              }),
                                            children: match$1 !== undefined ? null : JsxRuntime.jsxs(Button.make, {
                                                    size: "SM",
                                                    color: "Teal",
                                                    buttonClassName: css.uploadDocumentButton,
                                                    children: [
                                                      JsxRuntime.jsx(IconAdditionalUpload.make, {
                                                            size: "MD",
                                                            color: "White"
                                                          }),
                                                      "Upload Image"
                                                    ]
                                                  })
                                          })
                                    ]
                                  })
                            ]
                          }),
                      JsxRuntime.jsxs(Row.make, {
                            className: css.row,
                            children: [
                              JsxRuntime.jsx(Col.make, {
                                    className: css.labelContainer,
                                    md: 3,
                                    lg: 3,
                                    children: JsxRuntime.jsx(Label.make, {
                                          forId: "blog-post-form--published-at",
                                          children: "Publish Date"
                                        })
                                  }),
                              JsxRuntime.jsxs(Col.make, {
                                    className: css.fieldContainer,
                                    md: 3,
                                    lg: 3,
                                    children: [
                                      JsxRuntime.jsx(DatePicker.make, {
                                            id: "blog-post-form--publishedAt",
                                            value: Belt_Option.map(form.input.publishedAt, (function (prim) {
                                                    return prim;
                                                  })),
                                            onDayChange: (function (date, param, param$1) {
                                                form.updatePublishedAt((function (input, value) {
                                                        return {
                                                                title: input.title,
                                                                seoTitle: input.seoTitle,
                                                                metaDescription: input.metaDescription,
                                                                approved: input.approved,
                                                                blogPostCategoryId: input.blogPostCategoryId,
                                                                publishedAt: value
                                                              };
                                                      }), Caml_option.some(date));
                                              }),
                                            className: css.publishedAtPicker,
                                            position: "OnRight"
                                          }),
                                      fieldError(form.publishedAtResult)
                                    ]
                                  })
                            ]
                          }),
                      JsxRuntime.jsxs(Row.make, {
                            className: css.row,
                            children: [
                              JsxRuntime.jsx(Col.make, {
                                    className: css.labelContainer,
                                    md: 3,
                                    lg: 3,
                                    children: JsxRuntime.jsx(Label.make, {
                                          forId: "blog-post-form--approved",
                                          children: "Published"
                                        })
                                  }),
                              JsxRuntime.jsx(Col.make, {
                                    className: css.fieldContainer,
                                    md: 9,
                                    lg: 9,
                                    children: JsxRuntime.jsxs(Select.make, {
                                          id: "blog-post-form--approved",
                                          value: form.input.approved,
                                          onChange: (function ($$event) {
                                              form.updateApproved((function (input, value) {
                                                      return {
                                                              title: input.title,
                                                              seoTitle: input.seoTitle,
                                                              metaDescription: input.metaDescription,
                                                              approved: value,
                                                              blogPostCategoryId: input.blogPostCategoryId,
                                                              publishedAt: input.publishedAt
                                                            };
                                                    }), $$event.target.value);
                                            }),
                                          children: [
                                            JsxRuntime.jsx(Select.$$Option.make, {
                                                  value: "false",
                                                  children: "False"
                                                }, "approved-false"),
                                            JsxRuntime.jsx(Select.$$Option.make, {
                                                  value: "true",
                                                  children: "True"
                                                }, "approved-true")
                                          ]
                                        })
                                  })
                            ]
                          }),
                      JsxRuntime.jsxs(Row.make, {
                            className: css.row,
                            children: [
                              JsxRuntime.jsx(Col.make, {
                                    className: css.labelContainer,
                                    md: 3,
                                    lg: 3,
                                    children: JsxRuntime.jsx(Label.make, {
                                          forId: "blog-post-form--blog-post-category-id",
                                          children: "Category"
                                        })
                                  }),
                              JsxRuntime.jsx(Col.make, {
                                    className: css.fieldContainer,
                                    md: 9,
                                    lg: 9,
                                    children: JsxRuntime.jsx(Select.make, {
                                          id: "blog-post-form--category",
                                          value: form.input.blogPostCategoryId,
                                          onChange: (function ($$event) {
                                              form.updateBlogPostCategoryId((function (input, value) {
                                                      return {
                                                              title: input.title,
                                                              seoTitle: input.seoTitle,
                                                              metaDescription: input.metaDescription,
                                                              approved: input.approved,
                                                              blogPostCategoryId: value,
                                                              publishedAt: input.publishedAt
                                                            };
                                                    }), $$event.target.value);
                                            }),
                                          children: Belt_Array.map(categories, (function (category) {
                                                  return JsxRuntime.jsx(Select.$$Option.make, {
                                                              value: ID.toString(category.id),
                                                              children: category.title
                                                            }, "blog-post-form--category-" + ID.toString(category.id));
                                                }))
                                        })
                                  })
                            ]
                          }),
                      tmp,
                      JsxRuntime.jsxs(Row.make, {
                            className: "row",
                            children: [
                              JsxRuntime.jsx(Col.make, {
                                    className: css.buttonHiddenContainer,
                                    md: 3,
                                    lg: 3,
                                    children: JsxRuntime.jsx("div", {})
                                  }),
                              JsxRuntime.jsx(Col.make, {
                                    className: css.buttonCreateContainer,
                                    md: 4,
                                    lg: 4,
                                    children: JsxRuntime.jsx(Button.make, {
                                          size: "LG",
                                          color: "Primary",
                                          expanded: true,
                                          disabled: form.submitting,
                                          visuallyDisabled: match$3 === undefined,
                                          busy: form.submitting,
                                          submit: true,
                                          children: "Create Blog Post"
                                        })
                                  }),
                              JsxRuntime.jsx(Col.make, {
                                    className: css.buttonCancelContainer,
                                    md: 5,
                                    lg: 5,
                                    children: JsxRuntime.jsx(Button.make, {
                                          size: "LG",
                                          color: "White",
                                          expanded: true,
                                          busy: false,
                                          submit: true,
                                          onClick: (function (e) {
                                              e.preventDefault();
                                              Url.visit(Routes_BlogPost.Dashboard.index);
                                            }),
                                          children: "Cancel"
                                        })
                                  })
                            ]
                          })
                    ]
                  }),
              onSubmit: (function ($$event) {
                  $$event.preventDefault();
                  form.submit();
                })
            });
}

var make = BlogPostNewForm$1;

export {
  css ,
  BlogPostNewForm ,
  reducer ,
  make ,
}
/* css Not a pure module */
